
import { Options, Vue } from 'vue-class-component';
import { SizedImage } from '@/models/SizedImage';

@Options({
  name: 'ImageTitleView',
  props: ['image', 'title', 'date', 'author'],
  mounted() {
    const image = this.$props.image as SizedImage;
    const ratio = (image.height / image.width) * 100;
    this.$refs.imageContainer.style.paddingTop = `${ratio}%`;
  },
})
export default class ImageTitleView extends Vue {
}
