import { createStore } from 'vuex';
import { Page, PageContent } from '@/models/Page';
import { PageIndex, PagePreview } from '@/models/PageIndex';

export interface State {
    pages: Map<string, PageContent>,
    pageIndex: PageIndex,
    theme: string,
    contact: string,
    delayedTheme: string
}

export default createStore<State>({
  state: {
    pages: new Map<string, PageContent>(),
    pageIndex: new Array<PagePreview>(),
    contact: '',
    theme: 'light',
    delayedTheme: 'light',
  },
  mutations: {
    cachePage(state: State, payload: Page) {
      state.pages.set(payload.key, payload.page);
      console.log(payload.key, payload.page);
    },
    cachePageIndex(state: State, payload: PageIndex) {
      state.pageIndex = payload;
      console.log(payload);
    },
    cacheContact(state: State, payload: string) {
      state.contact = payload;
      console.log(payload);
    },
    updateTheme(state: State, payload: string) {
      state.theme = payload;
    },
    updateDelayedTheme(state: State, payload: string) {
      state.delayedTheme = payload;
    },
  },
  actions: {
  },
});
