<template>
  <div class="edit-heading-cell">
    <div class="cell-input">
      <label for="heading-text">Heading Text</label>
      <input
          id="heading-text"
          type="text"
          :value="title"
          @input="update('title', $event.target.value)"
      >
    </div>
    <div class="cell-input">
      <label for="heading-level">Heading Level</label>
      <select
          id="heading-level"
          :value="size"
          @input="update('size', $event.target.value)"
      >
        <option value="h1">1</option>
        <option value="h2">2</option>
        <option value="h3">3</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditParagraphCell',
  props: {
    modelValue: {
      type: Object,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    safeCell() {
      return this.$props.modelValue || { type: 'heading', data: { title: '', size: 'h1' } };
    },
    safeData() {
      return this.safeCell.data || { title: '', size: 'h1' };
    },
    title() {
      return this.safeData.title;
    },
    size() {
      return this.safeData.size;
    },
  },
  methods: {
    update(objectPath, value) {
      const deepCellCopy = JSON.parse(JSON.stringify(this.safeCell));
      deepCellCopy.data[objectPath] = value;
      this.$emit('update:modelValue', deepCellCopy);
    },
  },
};
</script>

<style scoped>

.edit-heading-cell {
  margin: 10px 20px 20px 20px;
}

.edit-heading-cell .cell-input {
  margin-bottom: 10px;
}

.edit-heading-cell .cell-input label {
  display: block;
  margin-bottom: 5px;
}

.edit-heading-cell .cell-input input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.edit-heading-cell .cell-input select {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

</style>
