
import { Options, Vue } from 'vue-class-component';
import SizedImageView from '@/components/SizedImageView.vue';

@Options({
  name: 'ImageCellView',
  props: ['image', 'wide'],
  components: {
    SizedImageView,
  },
})
export default class ImageCellView extends Vue {
}
