import { PageContent } from '@/models/Page';

export default class PageContentLoader {
  private static PAGE_BASE_URL = '/articles'

  static async load(key: string): Promise<PageContent> {
    const pageSpecificBaseUrl = `${this.PAGE_BASE_URL}/${key}`;
    const articleResponse = await fetch(`${pageSpecificBaseUrl}/article.json`);
    const article = await articleResponse.json() as PageContent;

    const imageResponse = await fetch(`${pageSpecificBaseUrl}/${article.hero.url}`);
    const imageBlob = await imageResponse.blob();
    article.hero.image = URL.createObjectURL(imageBlob);

    return article;
  }
}
