import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ((_ctx.size && _ctx.size === 'h1') || _ctx.size === undefined)
      ? (_openBlock(), _createElementBlock("h1", {
          key: 0,
          class: _normalizeClass(["heading", {'centered': _ctx.centered !== false }])
        }, _toDisplayString(_ctx.title), 3))
      : _createCommentVNode("", true),
    (_ctx.size && _ctx.size === 'h2')
      ? (_openBlock(), _createElementBlock("h2", {
          key: 1,
          class: _normalizeClass(["heading", {'centered': _ctx.centered !== false }])
        }, _toDisplayString(_ctx.title), 3))
      : _createCommentVNode("", true),
    (_ctx.size && _ctx.size === 'h3')
      ? (_openBlock(), _createElementBlock("h3", {
          key: 2,
          class: _normalizeClass(["heading", {'centered': _ctx.centered !== false }])
        }, _toDisplayString(_ctx.title), 3))
      : _createCommentVNode("", true)
  ], 64))
}