<template>
  <body-view @drop="drop" @dragover="allowDrop">
    <h1 class="centered-responsive less-margin">Article Editor</h1>
    <div class="metadata">
      <div class="input-group">
        <label for="title">Title</label>
        <input v-model="title" type="text" id="title">
      </div>
      <div class="input-group">
        <label for="author">Author</label>
        <input v-model="author" type="text" id="author">
      </div>
      <div class="input-group">
        <label for="date">Date</label>
        <input v-model="date" type="text" id="date">
      </div>
    </div>
    <template v-for="(cell, index) in cells" :key="index">
      <div class="container">
        <div class="container-header">
          <div class="action" @click="addCell(index)">+</div>
          <div class="action" @click="moveCellUp(index)">⏶</div>
          <div class="action" @click="moveCellDown(index)">⏷</div>
          <div class="action" @click="removeCell(index)">×</div>
        </div>
        <editor-cell
            :modelValue="cell"
            @update:modelValue="cells[index] = $event"
        />
      </div>
    </template>
    <button class="add-cell" @click="addCell(null)">Add Cell</button>
    <button class="save" @click="save">Save</button>
  </body-view>
</template>

<script>
import EditorCell from '../components/cells/EditorCell.vue';
import BodyView from '../components/BodyView.vue';

export default {
  name: 'Editor',
  components: { EditorCell, BodyView },
  data: () => ({
    cells: [{
      type: 'heading',
      data: {
        title: 'Example',
        size: 'h1',
      },
    },
    {
      type: 'code',
      data: {
        language: 'javascript',
        code: '',
        filename: '',
      },
    }],
    title: 'My Title',
    author: 'Franz',
    date: '1st January 2023',
  }),
  methods: {
    addCell(index) {
      if (index === null) {
        this.cells.push({
          type: 'paragraph',
          data: {
            body: '',
          },
        });
      } else {
        this.cells.splice(index + 1, 0, {
          type: 'paragraph',
          data: {
            body: '',
          },
        });
      }
    },
    removeCell(index) {
      this.cells.splice(index, 1);
    },
    moveCellUp(index) {
      if (index === 0) return;
      const temp = this.cells[index];
      this.cells[index] = this.cells[index - 1];
      this.cells[index - 1] = temp;
    },
    moveCellDown(index) {
      if (index === this.cells.length - 1) return;
      const temp = this.cells[index];
      this.cells[index] = this.cells[index + 1];
      this.cells[index + 1] = temp;
    },
    downloadAsFile(string) {
      const element = document.createElement('a');
      element.target = '_blank';
      const file = new Blob([string], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = 'blog.json';
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      document.body.remove(element);
    },
    save() {
      const data = {
        title: this.title,
        author: this.author,
        date: this.date,
        hero: {
          url: 'full.jpg',
          width: 3000,
          height: 1000,
        },
        cells: this.cells,
      };
      const json = JSON.stringify(data, null, 2);
      this.downloadAsFile(json);
    },
    allowDrop(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      if (event.dataTransfer.items) {
        const first = event.dataTransfer.items[0];
        if (first.kind === 'file') {
          const file = first.getAsFile();
          const reader = new FileReader();
          reader.onload = (e) => {
            const json = e.target.result;
            const data = JSON.parse(json);
            this.title = data.title;
            this.author = data.author;
            this.date = data.date;
            this.cells = data.cells;
          };
          reader.readAsText(file);
        }
      }
    },
  },
};
</script>

<style scoped>
@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.container {
  margin-top: 50px;
}

.container:first-of-type {
  margin-top: 0;
}

button {
  margin-top: 20px;
  height: 50px;
  width: 150px;
  align-self: center;
  background-color: hsl(206, 91%, 38%);
  border: solid 1px hsl(200, 88%, 43%);
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
}

button:first-of-type {
  margin-top: 50px;
}

.save {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s infinite;
}

.container-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -10px;
}

.container-header .action {
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 3px;
  background-color: hsl(206, 91%, 38%);
  border: solid 1px hsl(200, 88%, 43%);
  height: 20px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-family: "Basier Circle", -apple-system, sans-serif;
  text-align: center;
  color: hsl(206, 91%, 68%);
  cursor: pointer;
  margin-right: 10px;
}

.metadata {
  display: flex;
  justify-content: space-between;
  align-self: center;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 10px;
}

.input-group:first-of-type {
  margin-left: 0;
}

.input-group label {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}

.input-group input {
  height: 20px;
  font-size: 12px;
  padding: 5px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

h1.less-margin {
  margin-bottom: 20px;
}

</style>
