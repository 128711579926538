
import { Options, Vue } from 'vue-class-component';
import { SizedImage } from '@/models/SizedImage';

@Options({
  name: 'SizedImageView',
  props: ['image'],
  data() {
    return {
      hasLoaded: false,
    };
  },
  mounted() {
    const image = this.$props.image as SizedImage;
    const ratio = (this.$props.image.height / this.$props.image.width) * 100;
    this.$refs.imageContainer.style.paddingTop = `${ratio}%`;
    if (this.$refs.img.complete) {
      this.loaded();
    }
    this.$refs.img.addEventListener('load', this.loaded);
  },
  methods: {
    loaded() {
      this.hasLoaded = true;
    },
  },
})
export default class SizedImageView extends Vue {
}
