<template>
  <navigation-bar v-if="initialLoad"></navigation-bar>
  <router-view v-slot="{ Component }" v-if="show">
    <transition
      :css="false"
      mode="out-in"
      @enter="transitionEnter"
      @leave="transitionLeave"
    >
      <component
        :is="Component"
        :key="$route.params.page"
      />
    </transition>
  </router-view>
  <loading-view :state="state"></loading-view>
</template>

<style lang="less">
html, body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: "Basier Circle", -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
import NavigationBar from '@/components/NavigationBar.vue';
import LoadingView from '@/components/LoadingView.vue';
import store from '@/store/store';
import PageContentLoader from '@/loader/PageContentLoader';
import PageIndexLoader from '@/loader/PageIndexLoader';
import { nextTick } from 'vue';
import PageContactLoader from '@/loader/PageContactLoader';

export default {
  components: { LoadingView, NavigationBar },
  data() {
    return {
      state: 'none',
      show: false,
      initialLoad: false,
    };
  },
  computed: {
    theme() {
      return this.$store.theme;
    },
  },
  async mounted() {
    this.$data.state = 'loading';
    await this.$router.isReady();
    await Promise.all([this.sleep(250), this.load()]);
    this.$data.state = 'loaded';
    await this.sleep(250);
    this.show = true;
    this.initialLoad = true;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    await this.transitionEnter(null, () => {});
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async load() {
      let title = '';
      if (this.$route.name === 'Article') {
        const key = this.$route.params.page;
        const page = await PageContentLoader.load(key);
        store.commit('cachePage', {
          key, page,
        });
        title = page.title;
      }
      if (this.$route.name === 'Contact') {
        const contact = await PageContactLoader.load();
        store.commit('cacheContact', contact);
        title = 'Contact';
      }
      if (this.$route.path === '/') {
        const articles = await PageIndexLoader.load();
        store.commit('cachePageIndex', articles);
      }
      document.title = `${title + (title !== '' ? ' | ' : '')}Franz Bender`;
    },
    async doLeave() {
      this.$data.state = 'loading';
      await Promise.all([this.sleep(250), this.load()]);

      // fade to black/white
      this.$data.state = 'loaded';
      await this.sleep(250);
    },
    async transitionLeave(el, done) {
      // load and show spinner
      await this.doLeave();
      done();
    },
    async transitionEnter(el, done) {
      const theme = this.$route.meta.theme ?? 'light';

      window.document.body.classList.remove(this.$store.state.theme);
      window.document.body.classList.add(theme);
      // update theme for page
      this.$store.commit('updateTheme', theme);

      window.scrollTo(0, 0);

      this.$data.state = 'fadeout';

      await this.sleep(1000);
      // update theme for flood fill background
      this.$store.commit('updateDelayedTheme', theme);
      done();
    },
  },
};
</script>
