import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading_cell_view = _resolveComponent("heading-cell-view")!
  const _component_paragraph_cell_view = _resolveComponent("paragraph-cell-view")!
  const _component_image_cell_view = _resolveComponent("image-cell-view")!
  const _component_code_cell_view = _resolveComponent("code-cell-view")!
  const _component_latex_cell_view = _resolveComponent("latex-cell-view")!
  const _component_iframe_cell_view = _resolveComponent("iframe-cell-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cell", {'visible': _ctx.visible}]),
    ref: "cellElement"
  }, [
    (_ctx.cell.type === 'heading')
      ? (_openBlock(), _createBlock(_component_heading_cell_view, {
          key: 0,
          title: _ctx.cell.data.title,
          size: _ctx.cell.data.size,
          centered: _ctx.cell.data.centered
        }, null, 8, ["title", "size", "centered"]))
      : _createCommentVNode("", true),
    (_ctx.cell.type === 'paragraph')
      ? (_openBlock(), _createBlock(_component_paragraph_cell_view, {
          key: 1,
          body: _ctx.cell.data.body
        }, null, 8, ["body"]))
      : _createCommentVNode("", true),
    (_ctx.cell.type === 'image')
      ? (_openBlock(), _createBlock(_component_image_cell_view, {
          key: 2,
          image: _ctx.cell.data.image,
          wide: _ctx.cell.data.wide
        }, null, 8, ["image", "wide"]))
      : _createCommentVNode("", true),
    (_ctx.cell.type === 'code')
      ? (_openBlock(), _createBlock(_component_code_cell_view, {
          key: 3,
          code: _ctx.cell.data.code,
          language: _ctx.cell.data.language,
          filename: _ctx.cell.data.filename
        }, null, 8, ["code", "language", "filename"]))
      : _createCommentVNode("", true),
    (_ctx.cell.type === 'latex')
      ? (_openBlock(), _createBlock(_component_latex_cell_view, {
          key: 4,
          latex: _ctx.cell.data.latex
        }, null, 8, ["latex"]))
      : _createCommentVNode("", true),
    (_ctx.cell.type === 'iframe')
      ? (_openBlock(), _createBlock(_component_iframe_cell_view, {
          key: 5,
          url: _ctx.cell.data.url,
          width: _ctx.cell.data.width,
          height: _ctx.cell.data.height
        }, null, 8, ["url", "width", "height"]))
      : _createCommentVNode("", true)
  ], 2))
}