<template>
  <div class="edit-latex-cell">
    <div class="cell-body">
      <textarea :value="latex" @input="update('latex', $event.target.value)"></textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditLatexCell',
  props: {
    modelValue: {
      type: Object,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    safeCell() {
      return this.$props.modelValue || { type: 'latex', data: { latex: '' } };
    },
    safeData() {
      return this.safeCell.data || { latex: '' };
    },
    latex() {
      return this.safeData.latex;
    },
  },
  methods: {
    update(objectPath, value) {
      const deepCellCopy = JSON.parse(JSON.stringify(this.safeCell));
      deepCellCopy.data[objectPath] = value;
      this.$emit('update:modelValue', deepCellCopy);
    },
  },
};
</script>

<style scoped>

.edit-latex-cell {
  margin: 5px 20px 20px 20px;
}

.cell-header-left-item-value select {
  width: 100px;
}

.cell-header-left-item-value input {
  width: 100px;
}

.cell-body {
  margin-top: 10px;
}

.cell-body textarea {
  width: 100%;
  height: 250px;
  box-sizing: border-box;
  font-family: "Baison Mono", monospace;
  font-size: 16px;
  resize: vertical;
}

</style>
