<template>
  <div class="editor-cell" ref="cellElement">
    <div class="header-container">
      <select :value="modelValue.type" @input="updateType($event.target.value)" class="type-selector">
        <option value="code">Code</option>
        <option value="paragraph">Paragraph</option>
        <option value="image">Image</option>
        <option value="latex">Latex</option>
        <option value="heading">Heading</option>
        <option value="iframe">Iframe</option>
      </select>
      <Switch v-model="preview">
        <template v-slot:left>
          <span>PREV</span>
        </template>
        <template v-slot:right>
          <span>EDIT</span>
        </template>
      </Switch>
    </div>
    <any-cell :cell="modelValue" v-if="preview"></any-cell>
    <div v-if="!preview">
      <edit-code-cell
          v-if="modelValue.type == 'code'"
          :modelValue="modelValue"
          @update:modelValue="$emit('update:modelValue', $event)"
      >
      </edit-code-cell>
      <edit-paragraph-cell
          v-if="modelValue.type == 'paragraph'"
          :modelValue="modelValue"
          @update:modelValue="$emit('update:modelValue', $event)">
      </edit-paragraph-cell>
      <edit-image-cell
          v-if="modelValue.type == 'image'"
          :modelValue="modelValue"
          @update:modelValue="$emit('update:modelValue', $event)">
      </edit-image-cell>
      <edit-latex-cell
          v-if="modelValue.type == 'latex'"
          :modelValue="modelValue"
          @update:modelValue="$emit('update:modelValue', $event)">
      </edit-latex-cell>
      <edit-heading-cell
          v-if="modelValue.type == 'heading'"
          :modelValue="modelValue"
          @update:modelValue="$emit('update:modelValue', $event)">
      </edit-heading-cell>
      <edit-iframe-cell
          v-if="modelValue.type == 'iframe'"
          :modelValue="modelValue"
          @update:modelValue="$emit('update:modelValue', $event)">
      </edit-iframe-cell>
    </div>

  </div>
</template>

<script>
import Switch from '@/components/Switch.vue';
import EditCodeCell from '@/components/cells/EditCodeCell.vue';
import EditParagraphCell from '@/components/cells/EditParagraphCell.vue';
import EditImageCell from '@/components/cells/EditImageCell.vue';
import EditLatexCell from '@/components/cells/EditLatexCell.vue';
import EditHeadingCell from '@/components/cells/EditHeadingCell.vue';
import EditIframeCell from '@/components/cells/EditIframeCell.vue';
import AnyCell from './AnyCell.vue';

export default {
  name: 'EditorCell',
  components: {
    EditIframeCell,
    EditLatexCell,
    EditImageCell,
    EditParagraphCell,
    EditCodeCell,
    EditHeadingCell,
    Switch,
    AnyCell,
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      preview: false,
    };
  },
  methods: {
    updateType(value) {
      const deepCellCopy = JSON.parse(JSON.stringify(this.modelValue));
      deepCellCopy.type = value;
      switch (value) {
        case 'code':
          deepCellCopy.data = { code: '', language: 'plain', filename: '' };
          break;
        case 'paragraph':
          deepCellCopy.data = { body: '' };
          break;
        case 'image':
          deepCellCopy.data = { image: { url: '', width: 250, height: 150 }, wide: false };
          break;
        case 'latex':
          deepCellCopy.data = { latex: '' };
          break;
        case 'heading':
          deepCellCopy.data = { title: '', size: 'h1' };
          break;
        case 'iframe':
          deepCellCopy.data = { url: '', width: 800, height: 600 };
          break;
        default:
          break;
      }
      this.$emit('update:modelValue', deepCellCopy);
    },
  },
};
</script>

<style scoped>

.editor-cell {
  background-color: hsl(206, 91%, 38%);
  border: solid 1px hsl(200, 88%, 43%);
  box-shadow: hsl(206, 91%, 38%) 0 0 40px 5px;
  border-radius: 10px;
}

.header-container {
  display: flex;
  justify-content: center;
  margin-top: -10px;
}

.type-selector {
  margin-right: 10px;
  display: inline-block;
  position: relative;
  height: 22px;
  border-radius: 3px;
  background-color: hsl(206, 91%, 38%);
  border: solid 1px hsl(200, 88%, 43%);
  cursor: pointer;
  -webkit-appearance: none;
  color: hsl(206, 91%, 68%);
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-family: "Basier Circle", -apple-system, sans-serif;
  text-align: center;
  text-align-last: center;
  padding: 0 10px;
}

</style>
