
import { Options, Vue } from 'vue-class-component';
import Prism from 'prismjs';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-hcl';
import 'prismjs/components/prism-typescript';
import 'prismjs/themes/prism-twilight.css';

@Options({
  name: 'CodeCellView',
  props: ['code', 'language', 'filename'],
  components: {},
  data() {
    return {
      codeHtml: '',
    };
  },
  computed: {
    langClass() {
      return `language-${this.$props.language}`;
    },
  },
  mounted() {
    let lang;
    let langString;

    if (this.$props.language in Prism.languages) {
      lang = Prism.languages[this.$props.language];
      langString = this.$props.language;
    } else {
      lang = Prism.languages.none;
      langString = 'none';
    }

    this.$data.codeHtml = Prism.highlight(this.$props.code, lang, langString);
  },
})
export default class CodeCellView extends Vue {
}
