
import { Options, Vue } from 'vue-class-component';
import SizedImageView from '@/components/SizedImageView.vue';

@Options({
  name: 'ArticlePreview',
  components: {
    SizedImageView,
  },
  props: ['image', 'title', 'text', 'date', 'article'],
  computed: {
    link() {
      return `${this.$props.article}`;
    },
  },
})
export default class ArticlePreview extends Vue {
}
