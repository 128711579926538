import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sized_image_view = _resolveComponent("sized-image-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["image-cell", {
    'fullWidth': _ctx.wide === undefined || _ctx.wide === true,
    'indented': _ctx.wide === false
  }])
  }, [
    _createVNode(_component_sized_image_view, { image: _ctx.image }, null, 8, ["image"])
  ], 2))
}