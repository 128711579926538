import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73ceedd7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "code" }
const _hoisted_2 = {
  key: 0,
  class: "header"
}
const _hoisted_3 = { class: "filename" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.filename)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.filename), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("pre", {
      class: _normalizeClass({
      'slim' : _ctx.filename !== undefined
    })
    }, [
      _createElementVNode("code", {
        class: _normalizeClass([_ctx.langClass])
      }, [
        _createElementVNode("div", { innerHTML: _ctx.codeHtml }, null, 8, _hoisted_4)
      ], 2)
    ], 2)
  ]))
}