
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'LoadingView',
  props: {
    state: String,
  },
  computed: {
    theme() {
      return this.$store.state.delayedTheme;
    },
  },
})
export default class LoadingView extends Vue {}
