
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'IframeCellView',
  props: ['width', 'height', 'url', 'name'],
  data() {
    return {
      clientWidth: 800,
      loading: true,
    };
  },
  computed: {
    ratio() {
      return this.$props.height / this.$props.width;
    },
    scaledHeight() {
      return `${this.clientWidth * this.ratio}px`;
    },
  },
  methods: {
    updateWidth() {
      this.clientWidth = this.$refs['component-wrapper'].clientWidth;
    },
  },
  mounted() {
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
    this.$refs.iframe.addEventListener('load', () => {
      this.loading = false;
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  },
})
export default class ImageCellView extends Vue {
}
