<template>
  <div class="edit-iframe-cell">
    <div class="cell-input">
      <div class="input-group">
        <label for="url">URL</label>
        <input
          id="url"
          type="text"
          v-model="url"
          @input="update('url', $event.target.value)"
        />
      </div>
      <div class="input-group">
        <label for="width">Width</label>
        <input
          id="width"
          type="number"
          v-model="width"
          @input="update('width', $event.target.value)"
        />
      </div>
      <div class="input-group">
        <label for="height">Height</label>
        <input
          id="height"
          type="number"
          v-model="height"
          @input="update('height', $event.target.value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditIframeCell',
  props: {
    modelValue: {
      type: Object,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    safeCell() {
      return this.$props.modelValue || {
        type: 'iframe', data: { url: '', width: 800, height: 600 },
      };
    },
    safeData() {
      return this.safeCell.data || { url: '', width: 800, height: 600 };
    },
    url() {
      return this.safeData.url;
    },
    width() {
      return this.safeData.width;
    },
    height() {
      return this.safeData.height;
    },
  },
  methods: {
    update(objectPath, value) {
      const deepCellCopy = JSON.parse(JSON.stringify(this.safeCell));
      let current = deepCellCopy.data;
      const path = objectPath.split('.');
      for (let i = 0; i < path.length - 1; i += 1) {
        current = current[path[i]];
      }
      current[path[path.length - 1]] = value;
      this.$emit('update:modelValue', deepCellCopy);
    },
  },
};
</script>

<style scoped>

.edit-iframe-cell {
  margin: 10px 20px 20px 20px;
}

.cell-input {
  margin: 10px 0;
}

.cell-input label {
  display: block;
  margin-bottom: 5px;
}

.cell-input input {
  width: 100%;
  box-sizing: border-box;
  font-family: "Basier Circle", -apple-system, sans-serif;
  font-size: 16px;
}

</style>
