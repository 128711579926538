<template>
  <div class="switch" @click="toggle">
    <div class="switch__inner">
      <div class="switch__track">
        <div class="switch__track__inner">
          <div class="switch__track__inner__left">
            <slot name="left"></slot>
          </div>
          <div class="switch__track__inner__right">
            <slot name="right"></slot>
          </div>
        </div>
      </div>
      <div class="switch__thumb" :class="{'switch__thumb--active': modelValue}">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Switch',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    toggle() {
      console.log('toggle');
      this.$emit('update:modelValue', !this.modelValue);
    },
  },
};
</script>

<style scoped>
.switch {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
  border-radius: 3px;
  background-color: hsl(206, 91%, 38%);
  border: solid 1px hsl(200, 88%, 43%);
  cursor: pointer;
}

.switch__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.switch__track {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.switch__track__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switch__track__inner__left {
  color: hsl(206, 91%, 68%);
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  width: 100%;
  text-align: center;
}

.switch__track__inner__right {
  color: hsl(206, 91%, 68%);
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  width: 100%;
  text-align: center;
}

.switch__thumb {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 20px;
  width: 40px;
  border-radius: 2px;
  background-color: hsl(200, 88%, 43%);
  transition: left 150ms ease;
}

.switch__thumb--active {
  left: 40px;
}

</style>
