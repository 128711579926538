
import { Options, Vue } from 'vue-class-component';
import BodyView from '@/components/BodyView.vue';

@Options({
  components: {
    BodyView,
  },
  computed: {
    me() {
      return this.$store.state.contact;
    },
  },
})
export default class Contact extends Vue {
}
