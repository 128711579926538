
import { Options, Vue } from 'vue-class-component';
import SizedImageView from '@/components/SizedImageView.vue';
import ImageTitleView from '@/components/ImageTitleView.vue';
import AnyCell from '@/components/cells/AnyCell.vue';
import BodyView from '@/components/BodyView.vue';
import { PageContent } from '@/models/Page';

@Options({
  name: 'Article',
  components: {
    SizedImageView,
    ImageTitleView,
    BodyView,
    AnyCell,
  },
  computed: {
    article() {
      const key = this.$route.params.page;
      const article = this.$store.state.pages.get(key) as PageContent;
      console.log(key, article, this.$store.state);
      return article;
    },
  },
})
export default class ArticlePreview extends Vue {
}
