import {
  createRouter, createWebHistory, RouteRecordRaw, routerKey,
} from 'vue-router';
import Home from '../views/Home.vue';
import Article from '../views/Article.vue';
import Contact from '../views/Contact.vue';
import Editor from '../views/Editor.vue';
import store from '../store/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      theme: 'dark',
    },
  },
  {
    path: '/editor',
    name: 'Editor',
    component: Editor,
    meta: {
      theme: 'blueprint',
    },
  },
  {
    name: 'Article',
    path: '/:page',
    component: Article,
    meta: {
      theme: 'transparent',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
