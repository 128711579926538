<template>
  <div class="edit-paragraph-cell">
      <div class="cell-body">
        <textarea :value="body" @input="update('body', $event.target.value)"></textarea>
      </div>
  </div>
</template>

<script>
export default {
  name: 'EditParagraphCell',
  props: {
    modelValue: {
      type: Object,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    safeCell() {
      return this.$props.modelValue || { type: 'paragraph', data: { body: '' } };
    },
    safeData() {
      return this.safeCell.data || { body: '' };
    },
    body() {
      return this.safeData.body;
    },
  },
  methods: {
    update(objectPath, value) {
      const deepCellCopy = JSON.parse(JSON.stringify(this.safeCell));
      deepCellCopy.data[objectPath] = value;
      this.$emit('update:modelValue', deepCellCopy);
    },
  },
};
</script>

<style scoped>

.edit-paragraph-cell {
  margin: 10px 20px 20px 20px;
}

.cell-body textarea {
  width: 100%;
  height: 250px;
  box-sizing: border-box;
  font-family: "Basier Circle", -apple-system, sans-serif;
  font-size: 16px;
  resize: vertical;
}

</style>
