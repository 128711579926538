import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-725f3f32"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "sizedImage",
  ref: "imageContainer"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "box" }
const _hoisted_4 = { class: "date" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "mobile" }
const _hoisted_7 = { class: "date" }
const _hoisted_8 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.$props.image.image ? _ctx.$props.image.image : _ctx.$props.image.url,
        alt: "Image"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.author) + " on " + _toDisplayString(_ctx.date), 1),
        _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.title), 1)
      ])
    ], 512),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.author) + " on " + _toDisplayString(_ctx.date), 1),
      _createElementVNode("h1", _hoisted_8, _toDisplayString(_ctx.title), 1)
    ])
  ]))
}