<template>
  <div class="edit-image-cell">
    <div class="cell-input">
      <label for="image-url">Image URL</label>
      <input
          id="image-url"
          type="text"
          :value="url"
          @input="update('image.url', $event.target.value)"
      >
    </div>
    <div class="cell-input">
      <label for="image-width">Image Width</label>
      <input
          id="image-width"
          type="text"
          :value="width"
          @input="update('image.width', $event.target.value)"
      >
    </div>
    <div class="cell-input">
      <label for="image-height">Image Height</label>
      <input
          id="image-height"
          type="text"
          :value="height"
          @input="update('image.height', $event.target.value)"
      >
    </div>
    <div class="cell-input">
      <label for="image-wide">Display as wide image</label>
      <input
          id="image-wide"
          type="checkbox"
          :checked="wide"
          @input="update('wide', $event.target.checked)"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditImageCell',
  props: {
    modelValue: {
      type: Object,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    safeCell() {
      return this.$props.modelValue || {
        type: 'image', data: { image: { url: '', width: 250, height: 150 }, wide: false },
      };
    },
    safeData() {
      return this.safeCell.data || { image: { url: '', width: 250, height: 150 }, wide: false };
    },
    safeImage() {
      return this.safeData.image || { url: '', width: 250, height: 150 };
    },
    url() {
      return this.safeImage.url;
    },
    width() {
      return this.safeImage.width;
    },
    height() {
      return this.safeImage.height;
    },
    wide() {
      return this.safeData.wide;
    },
  },
  methods: {
    update(objectPath, value) {
      const deepCellCopy = JSON.parse(JSON.stringify(this.safeCell));
      let current = deepCellCopy.data;
      const path = objectPath.split('.');
      for (let i = 0; i < path.length - 1; i += 1) {
        current = current[path[i]];
      }
      current[path[path.length - 1]] = value;
      this.$emit('update:modelValue', deepCellCopy);
    },
  },
};
</script>

<style scoped>

.edit-image-cell {
  margin: 10px 20px 20px 20px;
}

.cell-input {
  margin: 10px 0;
}

.cell-input label {
  display: block;
  margin-bottom: 5px;
}

.cell-input input {
  width: 100%;
  box-sizing: border-box;
  font-family: "Basier Circle", -apple-system, sans-serif;
  font-size: 16px;
}

</style>
