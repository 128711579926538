
import { Options, Vue } from 'vue-class-component';
import HeadingCellView from '@/components/cells/HeadingCellView.vue';
import ParagraphCellView from '@/components/cells/ParagraphCellView.vue';
import CodeCellView from '@/components/cells/CodeCellView.vue';
import ImageCellView from '@/components/cells/ImageCellView.vue';
import LatexCellView from '@/components/cells/LatexCellView.vue';
import IframeCellView from '@/components/cells/IframeCellView.vue';

@Options({
  name: 'AnyCell',
  components: {
    IframeCellView,
    HeadingCellView,
    ParagraphCellView,
    ImageCellView,
    CodeCellView,
    LatexCellView,
  },
  props: ['cell'],
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    check() {
      const rect = this.$refs.cellElement.getBoundingClientRect();
      const elemTop = rect.top + 100;
      const visible = elemTop < window.innerHeight;
      if (visible) {
        this.visible = true;
        window.removeEventListener('scroll', this.check);
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.check);
    this.check();
  },
  beforeUnmount() {
    if (!this.visible) {
      window.removeEventListener('scroll', this.check);
    }
  },
})
export default class AnyCell extends Vue {
}
