import Me from '@/assets/me.png';

export default class PageContactLoader {
  static async load(): Promise<string> {
    const imageResponse = await fetch(Me);
    const imageBlob = await imageResponse.blob();
    // eslint-disable-next-line no-console
    console.log(imageBlob);
    const image = URL.createObjectURL(imageBlob);
    return image;
  }
}
