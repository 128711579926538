import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0634a40a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["sizedImage", {'loaded': _ctx.hasLoaded}]),
      ref: "imageContainer"
    }, [
      _createElementVNode("img", {
        src: _ctx.$props.image.image ? _ctx.$props.image.image : _ctx.$props.image.url,
        ref: "img",
        alt: "Image"
      }, null, 8, _hoisted_1)
    ], 2)
  ]))
}