import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-97a0821e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "article" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image_title_view = _resolveComponent("image-title-view")!
  const _component_any_cell = _resolveComponent("any-cell")!
  const _component_body_view = _resolveComponent("body-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_image_title_view, {
      image: _ctx.article.hero,
      title: _ctx.article.title,
      date: _ctx.article.date,
      author: _ctx.article.author
    }, null, 8, ["image", "title", "date", "author"]),
    _createVNode(_component_body_view, { slim: true }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.article.cells, (cell, idx) => {
          return (_openBlock(), _createBlock(_component_any_cell, {
            cell: cell,
            key: idx
          }, null, 8, ["cell"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}