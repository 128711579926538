
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'NavigationBar',
  data() {
    return {
      showNavbar: true,
      currentScrollPosition: 0,
      transparentOverride: false,
    };
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
    styleClasses() {
      const isLightTheme = this.theme === 'light' || this.theme === undefined;
      const isTransparentThemeButOverride = this.theme === 'transparent' && this.transparentOverride;
      const isBlueprintThemeButOverride = this.theme === 'blueprint' && this.transparentOverride;

      return {
        hidden: !this.showNavbar,
        light: isLightTheme || isTransparentThemeButOverride || isBlueprintThemeButOverride,
        dark: this.theme === 'dark',
        transparent: (this.theme === 'transparent' || this.theme === 'blueprint') && !this.transparentOverride,
      };
    },
  },
  methods: {
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollPosition >= 10) {
        this.transparentOverride = true;
      } else {
        this.transparentOverride = false;
      }

      if (currentScrollPosition <= 100) {
        this.showNavbar = true;
        this.lastScrollPosition = 100;
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 100) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
})
export default class NavigationBar extends Vue {
}
