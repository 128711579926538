
import { Options, Vue } from 'vue-class-component';
import SizedImageView from '@/components/SizedImageView.vue';
import BodyView from '@/components/BodyView.vue';
import ArticlePreview from '@/components/ArticlePreview.vue';

@Options({
  components: {
    SizedImageView,
    BodyView,
    ArticlePreview,
  },
  computed: {
    articles() {
      return this.$store.state.pageIndex;
    },
  },
})
export default class Home extends Vue {
}
