<template>
  <div class="edit-code-cell">
    <div class="cell-header">
      <div class="cell-header-left">
        <div class="cell-header-left-item">
          <div class="cell-header-left-item-label">Language</div>
          <div class="cell-header-left-item-value">
            <select :value="language" @input="update('language', $event.target.value)">
              <option value="javascript">JavaScript</option>
              <option value="typescript">TypeScript</option>
              <option value="html">HTML</option>
              <option value="css">CSS</option>
              <option value="python">Python</option>
              <option value="bash">Bash</option>
              <option value="plain">Plain</option>
            </select>
          </div>
        </div>
        <div class="cell-header-left-item">
          <div class="cell-header-left-item-label">Filename</div>
          <div class="cell-header-left-item-value">
            <input type="text" :value="filename" @input="update('filename', $event.target.value)"/>
          </div>
        </div>
      </div>
      <div class="cell-body">
        <textarea :value="code" @input="update('code', $event.target.value)"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditCodeCell',
  props: {
    modelValue: {
      type: Object,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    safeCell() {
      return this.$props.modelValue || { type: 'code', data: { code: '', language: 'plain', filename: '' } };
    },
    safeData() {
      return this.safeCell.data || { code: '', language: 'plain', filename: '' };
    },
    code() {
      return this.safeData.code;
    },
    filename() {
      return this.safeData.filename;
    },
    language() {
      return this.safeData.language;
    },
  },
  methods: {
    update(objectPath, value) {
      const deepCellCopy = JSON.parse(JSON.stringify(this.safeCell));
      deepCellCopy.data[objectPath] = value;
      this.$emit('update:modelValue', deepCellCopy);
    },
  },
};
</script>

<style scoped>

.edit-code-cell {
  margin: 5px 20px 20px 20px;
}

.cell-header {
  display: flex;
  flex-direction: column;
}

.cell-header-left {
  display: flex;
  flex-direction: row;
}

.cell-header-left-item {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.cell-header-left-item-label {
  font-size: 12px;
  color: white;
}

.cell-header-left-item-value {
  margin-top: 5px;
}

.cell-header-left-item-value select {
  width: 100px;
}

.cell-header-left-item-value input {
  width: 100px;
}

.cell-body {
  margin-top: 10px;
}

.cell-body textarea {
  width: 100%;
  height: 250px;
  box-sizing: border-box;
  font-family: "Baison Mono", monospace;
  font-size: 16px;
  resize: vertical;
}

</style>
