import { PageIndex, PagePreview } from '@/models/PageIndex';

export default class PageIndexLoader {
  private static PAGE_INDEX_URL = 'articles/index.json'

  static async load(): Promise<PageIndex> {
    const result = await fetch(PageIndexLoader.PAGE_INDEX_URL);
    const jsonResult = await result.json();

    jsonResult.forEach((article: PagePreview) => {
      // eslint-disable-next-line no-param-reassign
      article.image.url = `articles/${article.image.url}`;
    });
    return jsonResult as PageIndex;
  }
}
