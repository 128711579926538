
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'ParagraphCellView',
  props: ['body'],
  methods: {
    latexToInlineSvg(latex: string) {
      const mj = (window as any).MathJax;
      const options = {
        em: 16,
        ex: 8,
      };
      const element = mj.tex2svg(latex, options);
      element.classList.add('inline-latex');
      return element.outerHTML;
    },
    applyTrivialMarkdown(value: string) {
      const cleaned = value.replace(/<(.|\n)*?>/g, '');
      const withBold = cleaned.replace(/\*([^*]*)\*/g, '<b>$1</b>');
      const withItalic = withBold.replace(/_([^_]*)_/g, '<i>$1</i>');
      return withItalic.replace(/\[(.*)\]\((.*)\)/g, '<a href="$2">$1</a>');
    },
    markdown(value: string) {
      let mode = '';
      let output = '';
      let buffer = '';
      // eslint-disable-next-line no-restricted-syntax
      for (const character of value) {
        if (mode === '') {
          if (character === '$') {
            output += this.applyTrivialMarkdown(buffer);
            buffer = '';
            mode = 'latex';
          } else {
            buffer += character;
          }
        } else if (mode === 'latex') {
          if (character === '$') {
            output += this.latexToInlineSvg(buffer);
            buffer = '';
            mode = '';
          } else {
            buffer += character;
          }
        }
      }
      output += this.applyTrivialMarkdown(buffer);
      return output;
    },
  },
  computed: {
    cleaned() {
      // poor man's markdown compiler
      return this.markdown(this.$props.body);
    },
  },
})
export default class ParagraphCellView extends Vue {
}
