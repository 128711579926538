
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'ParagraphCellView',
  props: ['latex'],
  components: {},
  computed: {
    svg() {
      const mj = (window as any).MathJax;
      const options = {
        em: 16,
        ex: 8,
      };
      const element = mj.tex2svg(this.$props.latex, options);
      return element.outerHTML;
    },
  },
})
export default class ParagraphCellView extends Vue {
}
